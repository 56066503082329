<template>
  <div class="exam-iframe">
    <iframe id="exam-iframe" ref="examIframe" :style="iframeStyle" width="100%" height="100%" :src="'/exa/#/'+path+'?isIframe=true'"></iframe>
  </div>
</template>

<script>
import { getUserInfo } from '@/utils/auth';

export default {
    name: "ExamIframe",
    props: {
      path: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        
      }
    },
    computed:{
      iframeStyle(){
        return "width:100%; border: 0px; min-height:"+(document.documentElement.clientHeight-300)+"px"
      },
      isLogin: function() {
        let userInfo = getUserInfo();
        return !!userInfo;
      },
    },
    created(){
      console.log("-----",this.path)
      if(!this.isLogin){
        this.$router.push('/login')
      }
    }
  }
</script>
<style scoped>
  
</style>
<style>
.exam-iframe .ant-card-body{
  margin: 0px;
  padding:4px;
  padding-bottom: 10px;
}
</style>