import { render, staticRenderFns } from "./ExamIframe.vue?vue&type=template&id=b4a9300c&scoped=true&"
import script from "./ExamIframe.vue?vue&type=script&lang=js&"
export * from "./ExamIframe.vue?vue&type=script&lang=js&"
import style1 from "./ExamIframe.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4a9300c",
  null
  
)

export default component.exports