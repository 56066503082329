<template>
  <ExamIframe :path="path"></ExamIframe>
</template>

<script>
import ExamIframe from './ExamIframe'
import { isStudent,isTeacher,isSchoolAdmin } from "@/utils/auth"

export default {
    name: "ExamPaperRead",
    components:{
      ExamIframe
    },
    data () {
      return {
        path: 'read'
      }
    },
    created(){
    },
    methods: {}
  }
</script>
<style scoped>
  
</style>